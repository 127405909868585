@tailwind base;
@tailwind components;
@tailwind utilities;
/* You can add global styles to this file, and also import other style files */
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

html {
  padding-right: 0px !important;
}

body {
  min-width: 320px;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Inter', sans-serif;
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@layer base {
  body {
    @apply bg-theme-white-variant-1 text-base text-theme-black;
  }
  hr {
    @apply border-common-subtitle;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
    padding-right: 8px;
  }

  input[type='password']::-ms-reveal,
  input[type='password']::-ms-clear {
    display: none;
  }

  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    @apply bg-theme-black/10;
  }

  ::-webkit-scrollbar-thumb {
    @apply rounded-3xl bg-theme-black/30;
  }

  ::-webkit-scrollbar-thumb:hover {
    @apply bg-theme-black/50;
  }
}

@layer components {
  .scroll-x-hidden::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .btn-primary-linear {
    background-image: linear-gradient(
      85.17deg,
      theme('colors.primary.DEFAULT') 0.44%,
      theme('colors.secondary.DEFAULT') 99.41%
    );
  }

  .modal {
    &__title {
      @apply border-b border-b-theme-black/20 p-4 text-lg font-bold;
    }

    &__content {
      @apply p-4;
    }

    &__footer {
      @apply border-t border-b-theme-black/20 p-4;
    }

    &__description {
      @apply text-sm font-normal text-common-description;
    }
  }

  .required-field {
    @apply after:ml-1 after:text-red-600 after:content-["*"];
  }

  .base-input {
    @apply w-full rounded-lg border border-theme-black/10 bg-theme-white text-left font-normal text-theme-black caret-primary;
    @apply focus:border-primary focus:outline-none !important;
    @apply placeholder:font-light placeholder:not-italic placeholder:text-theme-black/30;
    @apply disabled:bg-theme-black/5 disabled:focus:border-theme-black/10;

    &.error {
      @apply border-common-error focus:border-common-error;
    }

    &-sm {
      @apply py-2 pl-4 pr-2;
    }

    &-md {
      @apply py-4 pl-4 pr-3 text-md;
    }

    &-lg {
      @apply py-4 pl-4 pr-3 text-lg;
    }
  }

  .base-select {
    @apply base-input;
    @apply active:border-primary active:outline-none !important;
    @apply focus-within:border-primary focus-within:outline-none !important;
    @apply disabled:bg-theme-black/5 disabled:focus:border-theme-black/10;

    input {
      @apply outline-none;
    }

    &.error {
      @apply border-common-error focus:border-common-error;
    }

    &-sm {
      @apply py-2 pl-4 pr-10;
    }

    &-md {
      @apply py-4 pl-4 pr-10 text-md;
    }

    &-lg {
      @apply py-4 pl-4 pr-10 text-lg;
    }

    &-flight-sm {
      @apply py-2 pl-4 pr-2;
    }

    &-flight-md {
      @apply py-4 pl-4 pr-3 text-md;
    }

    &-flight-lg {
      @apply py-4 pl-4 pr-3 text-lg;
    }
  }

  .base-input-otp {
    @apply rounded-lg border border-common-border bg-transparent text-center font-bold caret-primary outline-none;
    @apply active:border-primary active:outline-none !important;
    @apply focus:border-primary focus:outline-none focus:ring-0 !important;
    @apply disabled:bg-theme-black/5 disabled:focus:border-theme-black/10;

    input {
      @apply outline-none;
    }

    &.error {
      @apply border-common-error focus:border-common-error;
    }

    &-sm {
      @apply !h-10 !w-10 pl-1;
    }

    &-md {
      @apply !h-12 !w-12 pl-1;
    }

    &-lg {
      @apply !h-14 !w-14 pl-2 text-md;
    }
  }

  .scroll-w {
    &-sm::-webkit-scrollbar {
      width: 5px;
    }
    &-md::-webkit-scrollbar {
      width: 10px;
    }
  }
}

/* CUSTOM REACT SLICK */

.slick-slider {
  .customDotSlide {
    @apply absolute bottom-1 left-1/2 flex min-h-[17px] -translate-x-1/2 items-center justify-center md:bottom-6 !important;
    li {
      @apply flex items-center !important;

      line-height: 2px;

      button {
        @apply mx-1 h-2 w-2 rounded-full bg-theme-white bg-opacity-40 text-[0] transition-all !important;
      }

      &.slick-active button {
        @apply h-2 w-2 bg-primary !important;
      }
    }
  }

  .customDotSlideCarosel {
    @apply relative z-10 mt-4 flex min-h-[17px] items-center justify-center  !important;
    li {
      @apply flex items-center !important;

      line-height: 2px;
      button {
        @apply mx-1 h-2.5 w-2.5 rounded-full bg-theme-black bg-opacity-40 text-[0] transition-all !important;
      }

      &.slick-active button {
        @apply h-2.5 w-2.5 rounded-full bg-primary !important;
      }
    }
  }
}

.rc-slider {
  margin: 0 auto !important;
}
.rc-slider-handle.rc-slider-handle-dragging {
  box-shadow: 0 0 0 2px #ff9558 !important;
}

.logoLoadingFullPage {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;

  div {
    position: absolute;
    width: 50px;
    height: 50px;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    background-image: url('/assets/images/logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;
    &:nth-child(1) {
      width: 75px;
      height: 75px;
      animation: heartbeat 1805ms ease 0s infinite normal none running;
    }
    &:nth-child(2),
    &:nth-child(3) {
      position: absolute;
      z-index: -100;
      animation: pulseLogo 1805ms infinite ease-out,
        filter 1805ms infinite ease-out;
    }
    &:nth-child(3) {
      z-index: -10;
      animation-delay: 0.2s;
    }
  }
}

.wrapper-full-image {
  & > span {
    width: 100% !important;
  }
}

@keyframes heartbeat {
  0% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }

  5% {
    transform: scale3d(1.5, 1.5, 1);
    opacity: 0.8;
  }

  12% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }

  16% {
    transform: scale3d(1.8, 1.8, 1);
    opacity: 0.4;
  }

  50% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }

  100% {
    transform: scale3d(1, 1, 1);
    opacity: 1;
  }
}

@keyframes pulseLogo {
  0% {
    transform: scale3d(1, 1, 1);
  }

  80% {
    transform: scale3d(9.6, 9.6, 1);
  }

  100% {
    transform: scale3d(12, 12, 1);
  }
}

@keyframes filter {
  0% {
    opacity: 0.3;
  }

  80% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.lds-ring {
  display: inline-block;
  position: relative;
  @apply border-t-primary;
  &--xs div,
  &--sm div {
    @apply border-2;
  }
  &--md div {
    @apply border-[3px];
  }
  &--lg div {
    @apply border-4;
  }

  &--xl div {
    @apply border-8;
  }
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  @apply h-full w-full border-solid border-transparent border-t-inherit;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeInOut {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ant-menu-item {
  @apply !flex items-center;
}
.ant-menu-item-group-title {
  @apply font-semibold;
}

.ant-menu-inline-collapsed .ant-menu-item-group-title {
  @apply invisible duration-0;
}

.view-table {
  border-collapse: collapse;
  width: 100%;

  td,
  th {
    @apply p-2 first:pl-0 last:pr-0;
  }

  td.not-found {
    @apply py-5 text-center;
  }

  thead > tr {
    @apply border-b-2;
    th {
      @apply font-semibold text-neutral-8;
    }
  }
  tbody > tr {
    @apply border-t;

    td {
      @apply text-neutral-10 font-semibold;
    }
  }
}

.react-datepicker .flight-list-calendar {
  > .react-datepicker__month-container {
    float: none;
    width: 100%;
    margin: 0;
    > .react-datepicker__month {
      margin: 0;
      border-right: 1px solid #d9d9d9;
      border-bottom: 1px solid #d9d9d9;
    }

    > .react-datepicker__header {
      background-color: transparent;
      border-bottom: none;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 20px;

      > .react-datepicker__day-names {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 0;

        > .react-datepicker__day-name {
          width: calc(100% / 7);
          margin: 6px 0;
          line-height: 21px;
        }
      }
    }

    > .react-datepicker__month > .react-datepicker__week {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 0;

      > .react-datepicker__day {
        width: calc(100% / 7);
        margin: 0;
        text-align: start;
        border-top: 1px solid #d9d9d9;
        border-left: 1px solid #d9d9d9;
        border-radius: 0;
      }
      > .react-datepicker__day--selected {
        border-radius: 0;
        pointer-events: none;
      }

      > .react-datepicker__day--outside-month > * {
        color: transparent;
      }
    }
  }
}

.filter-flight::-webkit-scrollbar {
  width: 5px;
}

.break-inside-pdf {
  @apply break-inside-avoid;
}

.background:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: -1;
  border-bottom: 1000px solid #eee;
}

@media print {
  header,
  aside {
    display: none;
  }

  body {
    -webkit-print-color-adjust: exact;
  }

  .print-table {
    tr,
    td,
    th,
    thead,
    tfoot {
      page-break-inside: avoid !important;
    }

    table {
      font-family: arial, sans-serif;
      width: 100%;
      page-break-inside: auto;
    }

    td,
    th {
      text-align: left;
      /* padding: 8px; */
    }

    tr {
      page-break-inside: avoid;
      page-break-after: auto;
    }
    thead {
      display: table-header-group;
    }
    tfoot {
      display: table-footer-group;
    }

    /* tr:nth-child(even) {
    @apply bg-gray-100;
  } */
  }
}

.view-scroll-table {
  border-collapse: collapse;
  width: 100%;
  display: block;

  th {
    @apply p-2 first:pl-0 last:pr-2;
  }
  td {
    @apply p-2 first:pl-0 last:pr-1;
  }

  td.not-found {
    @apply py-5 text-center;
  }

  thead > tr {
    @apply border-b-2;
    th {
      @apply font-semibold text-neutral-8;
    }
  }
  tbody > tr {
    @apply border-t;

    td {
      @apply text-neutral-10 font-semibold;
    }
  }

  tbody {
    display: block;
    overflow: auto;
  }
  thead,
  tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed; /* even columns width , fix width of table too*/
  }
}

.seat-map-scroll,
.search-airport-scroll,
.form-select-scroll {
  &::-webkit-scrollbar {
    @apply w-1;
  }

  &::-webkit-scrollbar-track {
    @apply bg-transparent;
  }

  &::-webkit-scrollbar-thumb {
    @apply bg-transparent;
  }

  &:hover {
    &::-webkit-scrollbar-track {
      @apply bg-theme-black/10;
    }

    &::-webkit-scrollbar-thumb {
      @apply bg-theme-black/30;
    }
  }
}
.ant-pagination-options {
  @apply !hidden;
}

.quill .ql-toolbar {
  @apply !rounded-ss-lg;
  @apply !rounded-se-lg;
}
.quill .ql-container {
  @apply !rounded-ee-lg;
  @apply !rounded-es-lg;
}

.bullet-custom {
  @apply !w-3 !h-3 bg-[#0027661A] rounded-[20px] inline-block duration-300;
}
.bullet-custom.bullet-custom-active {
  @apply !w-[32px]  bg-[#0067FF];
}
